.account-subtitle {
    color: #4c4c4c;
    font-size: 18px;
    margin-bottom: 30px;
    text-align: center;
}

p{
    margin-top: 0;
}

.row>* {
    
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}

.account-box {
    background-color: #fff;
    border: 1px solid #ededed;
    border-radius: 4px;
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
    margin: 0 auto;
    overflow: hidden;
    width: 480px;
  
}

.account-box label {
    color: #1f1f1f;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 5px;
}


.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
}
.col {
    flex: 1 0 0%;
}
.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.text-muted {
    color: #8e8e8e !important;
    white-space: nowrap;
}

a {
    text-decoration: none !important;
}

.account-box .form-group {
    margin-bottom: 25px;
}
.pass-group {
    position: relative !important;
    
}

span.fa.toggle-password\".fa-eye-slash {
    position: absolute !important;
    right: 17px !important;
    top: 50% !important;
    transform: translateY(-50%)!important;
    color: #757575 !important;
    cursor: pointer !important;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* BUTTON CSS */
.account-box .form-group {
    margin-bottom: 25px;
}

.text-center {
    text-align: center !important;
}


.account-btn {
    background: linear-gradient(to right, #9226dc 0%, #fc6075 100%);


   
    border: 0;
    border-radius: 4px;
    display: block;
    font-size: 22px;
    padding: 10px 26px;
    width: 100%;
}